@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;700&display=swap');

@font-face {
  font-family: 'Emirates-Bold';
  src: url('../../public//fonts/emirates/Emirates-Bold.woff2')
  format('woff2'),
  url('../../public//fonts/emirates/Emirates-Bold.woff')
  format('woff'),
  url("../../public//fonts/emirates/Emirates-Bold.ttf")
  format("truetype");
}


@font-face {
  font-family: 'Emirates-Light';
  src: url('../../public//fonts/emirates/Emirates-Light.woff2')
  format('woff2'),
  url('../../public//fonts/emirates/Emirates-Light.woff')
  format('woff'),
  url("../../public//fonts/emirates/Emirates-Light.ttf")
  format("truetype");
}

@font-face {
  font-family: 'Emirates-Medium';
  src: url('../../public//fonts/emirates/Emirates-Medium.woff2')
  format('woff2'),
  url('../../public//fonts/emirates/Emirates-Medium.woff')
  format('woff'),
  url("../../public//fonts/emirates/Emirates-Medium.ttf")
  format("truetype");
}

@font-face {
  font-family: 'YaHei-Bold';
  src: url('../../public//fonts/Microsoft-YaHei-Bold.ttf')
  format('ttf'),
  url('../../public//fonts/Microsoft-YaHei-Bold.ttf')
  format('ttf'),
  url("../../public//fonts/Microsoft-YaHei-Bold.ttf")
  format("truetype");
}

* {
  font-family: 'Noto Sans', sans-serif !important;
}
