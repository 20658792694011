@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;0,900;1,500&display=swap');
@font-face {
  font-family: 'Avenir LT Std';
  src: url('../public/fonts/AvenirLTStd-Medium.woff2') format('woff2'),
    url('../public/fonts/AvenirLTStd-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../public/fonts/Avenir-Roman.woff2') format('woff2'),
    url('../public/fonts/Avenir-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../public/fonts/Avenir-Black.woff2') format('woff2'),
    url('../public/fonts/Avenir-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'aeonik_trialregular';
  src: url('../public//fonts/aeonik/aeoniktrial-regular-webfont.woff2')
      format('woff2'),
    url('../public//fonts/aeonik/aeoniktrial-regular-webfont.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Monument Regular';
  src: url('../public//fonts/monument/monumentextended-regular.woff2')
      format('woff2'),
    url('../public//fonts/monument/monumentextended-regular.woff')
      format('woff');
  font-weight: lighter;
  font-style: normal;
}

* {
  font-family: 'Poppins' !important;
}

iframe {
  pointer-events: none;
}

.frame {
  pointer-events: all;
}

#avaturn-sdk-iframe {
  pointer-events: all;
}

#webpack-dev-server-client-overlay {
  display: none;
}
